import { Injectable } from '@angular/core';
import { Platform, AlertController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { StorageService } from './storage.service';
import { LogItem } from './../models/log-item.model';
import { TranslatePipe } from './../../shared/translate/pipes/translate.pipe';
import { environment } from './../../../environments/environment';
import { getAnalytics, logEvent, setConsent } from '@angular/fire/analytics';
import { CookieService } from 'ngx-cookie-service';
/* import { AlertController, LoadingController, Platform, ToastController } from '@ionic/angular';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { TranslatePipe } from './../../shared/translate/pipes/translate.pipe';
import { Router } from '@angular/router'; */
/* import { StorageService } from './storage.service';
import { userAndProfileData } from '../models/userAndProfileData.model';
import { Profile } from '../models/profile.model';
import { User } from '../models/user.model';
import { StorageItems } from '../constants/storage-items.constants'; */

@Injectable({ providedIn: 'root' })
export class LoggerService {
  offlineLog: LogItem[] = [];
  isCordova: any;
  plt: any;

  versionNumber: any;
  versionCode: any;
  packageName: any;
  appName: any;

  uid: any;
  email: any;
  cookiesEnabled: boolean;

  logLevel: 'NONE' | 'OFFLINE' | 'ANALYTICS' | 'ONLINE' = 'ONLINE';
  logCollectionName = 'SystemLog';

  // private loading?: HTMLIonLoadingElement;
  // private analytics: Analytics = inject(Analytics);

  constructor /*
    private loadingController: LoadingController,
     */(
    private platform: Platform,
    private router: Router,
    private storage: StorageService,
    private afs: AngularFirestore,
    private alertController: AlertController,
    private toastController: ToastController,
    private translate: TranslatePipe,
    private cookieService: CookieService
  ) {
    this.platform.ready().then(async () => {
      await this.init();
      this.plt = this.platform.platforms().join(',');
      this.isCordova = this.platform.is('cordova');
    });
  }

  init(): Promise<void> {
    return new Promise(async resolve => {
      try {
        const offlineLog = await this.loadLog();
        if (offlineLog) {
          this.offlineLog = offlineLog;
        } else {
          this.offlineLog = [];
        }
        resolve();
      } catch (error) {
        this.offlineLog = [];
        resolve();
      }
    });
  }

  enableAnalytics(analyticsEnabled: boolean) {
    // console.log(analyticsEnabled ? 'cookies enabled' : 'cookies disabled');
    setConsent({
      ad_storage: analyticsEnabled ? 'granted' : 'denied',
      analytics_storage: analyticsEnabled ? 'granted' : 'denied',
      functionality_storage: analyticsEnabled ? 'granted' : 'denied',
      personalization_storage: analyticsEnabled ? 'granted' : 'denied',
      security_storage: analyticsEnabled ? 'granted' : 'denied',
    });
  }

  logPageTransition(pageName: string) {
    logEvent(getAnalytics(), 'page_view', { 'page_title': pageName });
  }

  clearCookies() {
    const cookies = this.cookieService.getAll();
    /* if (cookies) console.log(cookies); */
    // Fallback if deleteAll doesn't work in current browser version
    // console.log('Cookies timeouted');
    for (const key in cookies) {
      if (cookies.hasOwnProperty(key)) {
        // console.log('Timeouting: ' + key);
        this.cookieService.set(key, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), '/');
      }
    }
    // console.log('Cookies all deleted!');
    this.cookieService.deleteAll('/');
  }

  loadLog(): Promise<LogItem[]> {
    return this.storage.get('log-array');
  }

  async setUserId(uid: any) {
    this.uid = uid;
    // console.log(this.uid);
    // setUserId(this.analytics, this.uid);
    if (this.logLevel === 'ONLINE') {
      this.uploadOldLog();
    }
  }

  async uploadOldLog() {
    if (this.offlineLog.length > 0) {
      let error = null;
      for (var i = 0, len = this.offlineLog.length; i < len; ++i) {
        const oldLogItem = this.offlineLog[i];
        const newLogItem = {
          id: null,
          title: oldLogItem.title,
          subtitle: oldLogItem.subtitle,
          type: oldLogItem.type,
          uid: this.getUserId() ? this.getUserId() : 'not_authenticated',
          versionNumber: this.getVersionNumber(),
          versionCode: this.getVersionCode(),
          packageName: this.getPackageName(),
          cordova: this.isCordova,
          platform: this.plt,
          currentUrl: this.router.url,
          email: this.email,
          appName: this.getAppName(),
          timeStamp: new Date(oldLogItem.timeStamp).toISOString(),
        };
        try {
          await this.addSystemLog(newLogItem, false);
        } catch (e) {
          error = e;
        }
      }
      if (!error) {
        this.offlineLog = [];
        this.storeLog();
      }
    }
  }

  getUserId() {
    return this.uid || null;
  }

  setVersionNumber(versionNumber: any) {
    this.versionNumber = versionNumber;
  }

  getVersionNumber(): any {
    return this.versionNumber || environment.version || null;
  }

  setVersionCode(versionCode: any) {
    this.versionCode = versionCode;
  }

  getVersionCode(): any {
    return this.versionCode || null;
  }

  setPackageName(packageName: any) {
    this.packageName = packageName;
  }

  getPackageName(): any {
    return this.packageName || null;
  }

  setAppName(appName: any) {
    this.appName = appName;
  }

  getAppName(): any {
    return this.appName || null;
  }

  setEmail(email: any) {
    this.email = email;
  }

  setCookies(enabled: boolean) {
    this.cookiesEnabled = enabled;
  }

  async addLogItem(
    title: any = null,
    subtitle: any = null,
    type: any = 'info',
    timeStamp: any = null
  ) {
    const item = new LogItem(
      null,
      title,
      subtitle,
      type,
      this.getUserId(),
      this.getVersionNumber(),
      this.getVersionCode(),
      this.getPackageName(),
      this.isCordova,
      this.plt,
      timeStamp,
      this.email,
      this.router.url as any,
      this.getAppName()
    );
    try {
      await this.addSystemLog(item);
    } catch (error) {
      console.error(error);
    }
  }

  private addSystemLog(item: LogItem, isNew: boolean = true): Promise<void> {
    // console.log(JSON.stringify(item));
    return new Promise(async (resolve, reject) => {
      let error = null;
      let analytics = false;
      if (this.logLevel === 'NONE') {
        resolve();
        return;
      }
      if (this.logLevel === 'ANALYTICS') {
        analytics = true;
      }
      if (this.logLevel === 'ONLINE' && item.uid !== null) {
        analytics = true;
        try {
          item.id = this.afs.createId();
          await this.afs
            .collection(this.logCollectionName)
            .doc(item.id)
            .set(Object.assign({}, item));
        } catch (e) {
          error = e;
        }
      }
      if (this.logLevel === 'OFFLINE' || (error && isNew) || item.uid === null) {
        this.pushOfflineLog(item);
      }
      if (analytics) {
        this.logAnalytics(item.type === 'error' ? 'error_happened' : item.title.toLowerCase());
      }
      if (error) {
        reject(error);
        return;
      }
      resolve();
      return;
    });
  }

  async pushOfflineLog(item: LogItem) {
    try {
      this.offlineLog.push(item);
      await this.storeLog();
    } catch (error) {
      console.error(error);
    }
  }

  storeLog(): Promise<LogItem[]> {
    return this.storage.set('log-array', this.offlineLog);
  }

  logAnalytics(eventName: any, params: any = {}) {
    if (this.getUserId() !== null) {
      params.uid = this.getUserId();
      params.when = new Date().toISOString();
      params.versionNumber = this.getVersionNumber();
      params.versioncode = this.getVersionCode();
      params.packageName = this.getPackageName();
    }
  }

  async handleError(error: any, show = false) {
    console.log(' ----- ERROR ----- ');
    console.error(error);
    const header = error.code || error.header || 'ERROR';
    const title = error.msg || error.message || error.data || error.reason || error || '';
    const subtitle = error.error || '';

    this.addLogItem(title, error, 'error');

    if (show) {
      const alert = await this.alertController.create({
        header: this.translate.transform(header),
        subHeader: this.translate.transform(title),
        message: this.translate.transform(subtitle),
        backdropDismiss: false,
        buttons: [this.translate.transform('OK')],
      });
      await alert.present();
      await alert.onDidDismiss();
    }
  }

  async showToast(
    message: any = '',
    color: any = 'dark',
    duration: number = 1000,
    position: 'top' | 'bottom' | 'middle' = 'bottom'
  ) {
    const toast = await this.toastController.create({
      message: this.translate.transform(message),
      color,
      duration,
      position,
      cssClass: 'ion-text-center',
    });
    await toast.present();
  }

  presentConfirmALert(
    header: any,
    message: any,
    rejectText: any = null,
    resolveText: any = null
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: this.translate.transform(header),
        message: this.translate.transform(message),
        backdropDismiss: false,
        buttons: [
          {
            text: this.translate.transform(rejectText) || this.translate.transform('CANCEL'),
            cssClass: 'dark',
            handler: () => {
              reject();
            },
          },
          {
            text: this.translate.transform(resolveText) || this.translate.transform('YES'),
            handler: () => {
              resolve();
            },
          },
        ],
      });
      await alert.present();
    });
  }

  /* // TODO: Fix analytics change
  setCookie(enabled: boolean) {
    // setAnalyticsCollectionEnabled(this.analytics, enabled);
  }

  getEmail(): any {
    return this.email || null;
  }

  convertNestedArray(values: any, type: 'toForm' | 'toFirebase') {
    let convertedValues: any;
    let originalValues: any;
    if (type === 'toFirebase') {
      convertedValues = values?.map((innerArray: any) => {
        const obj: { [key: string]: boolean | null | undefined } = {};
        if (innerArray?.length > 0) {
          innerArray.forEach((value: any, index: number) => {
            obj[`${index + 0}`] = value;
          });
        }
        return obj;
      });
      return convertedValues;
    } else if (type == 'toForm') {
      originalValues = values?.map((obj: any) => {
        const innerArray: (boolean | null | undefined)[] = [];
        for (let i = 0; i <= Object.keys(obj)?.length; i++) {
          innerArray?.push(obj[`${i}`]);
        }

        return innerArray;
      });
      return originalValues;
    }
  }

  formatTextForTranslate(text: string) {
    const wordRegex = /[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g;
    let x = text.match(wordRegex)!.join('_').toUpperCase();
    return x;
  }

  async presentLoading(message: any = null): Promise<void> {
    await this.dismissLoading();
    return new Promise(async resolve => {
      this.loading = await this.loadingController.create({
        message: message,
        backdropDismiss: false,
      });
      await this.loading.present();
      resolve();
    });
  }

  presentConfirmAlert(
    header: string,
    message?: string,
    subHeader?: string,
    buttons?: {
      okButton?: boolean;
      okButtonText?: string;
      cancelButton?: boolean;
      cancelButtonText?: string;
    }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      let alert: any;

      if (buttons?.okButton && buttons?.cancelButton) {
        alert = await this.alertController.create({
          header: header,
          message: message,
          subHeader: subHeader,
          buttons: [
            {
              text: buttons.cancelButtonText ? buttons.cancelButtonText : 'Cancel',
              cssClass: 'dark',
              handler: () => {
                reject();
              },
            },
            {
              text: buttons.okButtonText ? buttons.okButtonText : 'OK',
              cssClass: 'dark',
              handler: () => {
                resolve();
              },
            },
          ],
        });
      } else if (buttons?.okButton && !buttons.cancelButton) {
        alert = await this.alertController.create({
          header: header,
          message: message,
          subHeader: subHeader,
          buttons: [
            {
              text: buttons.okButtonText ? buttons.okButtonText : 'OK',
              cssClass: 'dark',
              handler: () => {
                resolve();
              },
            },
          ],
        });
      } else if (!(buttons?.okButton && buttons.cancelButton)) {
        alert = await this.alertController.create({
          header: header,
          message: message,
          subHeader: subHeader,
          backdropDismiss: true,
        });
      }
      await alert.present();
    });
  }

  dismissLoading(): Promise<void> {
    return new Promise(async resolve => {
      if (this.loading) {
        await this.loading.dismiss();
      }
      resolve();
    });
  }

  presentPasswordALert(header: any, message: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: header,
        message: message,
        backdropDismiss: false,
        inputs: [
          {
            name: 'password',
            type: 'password',
          },
        ],
        buttons: [
          {
            text: this.translate.transform('CANCEL'),
            handler: () => {
              reject();
            },
          },
          {
            text: this.translate.transform('OK'),
            handler: data => {
              resolve(data.password);
            },
          },
        ],
      });
      await alert.present();
    });
  }

  presentConfirmALert(
    header: any,
    message: any,
    rejectText: any = null,
    resolveText: any = null
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: this.translate.transform(header),
        message: this.translate.transform(message),
        backdropDismiss: false,
        buttons: [
          {
            text: rejectText || this.translate.transform('CANCEL'),
            cssClass: 'cancel-button',
            handler: () => {
              reject();
            },
          },
          {
            text: resolveText || this.translate.transform('YES'),
            cssClass: 'delete-button',
            handler: () => {
              resolve();
            },
          },
        ],
      });
      await alert.present();
    });
  }

  presentConfirmDeleteALert(message: any = null): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: this.translate.transform('DELETE_CONFIRM_HEADER'),
        message: message
          ? this.translate.transform(message) +
            '<br><br>' +
            this.translate.transform('DELETE_CONFIRM_MESSAGE')
          : this.translate.transform('DELETE_CONFIRM_MESSAGE'),
        backdropDismiss: false,
        inputs: [
          {
            name: 'delete',
            type: 'text',
            placeholder: this.translate.transform('DELETE'),
          },
        ],
        buttons: [
          {
            text: this.translate.transform('NO'),
            handler: () => {
              reject();
            },
          },
          {
            text: this.translate.transform('YES'),
            cssClass: 'delete-button',
            handler: data => {
              if (data.delete === this.translate.transform('DELETE')) {
                resolve();
              } else {
                reject();
              }
            },
          },
        ],
      });
      await alert.present();
    });
  }

  getSystemLog(uid: any, startDate: Date, endDate: Date, onlyErrors: boolean = false) {
    return this.afs
      .collection(this.logCollectionName, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('uid', '==', uid);
        query = query.where('timeStamp', '<', endDate.toISOString());
        query = query.where('timeStamp', '>=', startDate.toISOString());
        if (onlyErrors) {
          query = query.where('type', '==', 'error');
        }
        query = query.orderBy('timeStamp', 'desc');
        return query;
      })
      .valueChanges();
  }
 */
}
