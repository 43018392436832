export const environment = {
  firebase: {
    apiKey: 'AIzaSyDdT69m5yjMz0GGXwzvQ5WVXjIXmk9iYDc',
    authDomain: 'maszk2-prod.firebaseapp.com',
    projectId: 'maszk2-prod',
    storageBucket: 'maszk2-prod.appspot.com',
    messagingSenderId: '797629436279',
    appId: '1:797629436279:web:a94d6680a221e9aedd9172',
    measurementId: 'G-2VT7M784P1',
    vapidKey:
      'BNCkRYRi-q4e0-x3jIA2crGTYcArr9lQbXqDAvf9Fq6_UZfGGv_cfFterFF5A9A5cf7xsWCGkIIlFfRhDqcgcfg',
  },
  cfBase: 'https://europe-west1-maszk2-prod.cloudfunctions.net/',
  production: true,
  publicRSAKey: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4c9NBU4gSWyOqZ4B9wce
BI5laFDL5A79vZcCuIOLdSJ4EOmtt3GeX3Dfso6xDc1kf6LakBpuJ0lpnmxiBCZY
f/Bo1bavJaccE4Kvpnmegb/2dlk79v2SiWtsV0cf2GYi4soDan8PGvDQFP9ouMPO
7m3jB7jHVGRfRzpDjA4XuQfHQx8KKx2Sd+w+RK7q327jYbjb+sjpDtXlpADbKs+q
/IUECRYILI9WbdY2R5kR7kOFT9O+Kkzc1m3xfYLtnx/YS5CGYk0bV3gWESyoyZaY
bnhgr5MxRy90Q3zemwijJUWc7Lax+mL62sizX+rj5vqjW4XYNZvM9jU2cAJfa60b
lwIDAQAB
-----END PUBLIC KEY-----`,
  id: 'prod',
  region: 'europe-west1',
  version: '7.2.7',
  emulatorHost: 'localhost',
};
