import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';
import { User } from './../models/user.model';
import { FBaseService } from './firebase/f-base.service';
import { Profile } from './../models/profile.model';
import { userAndProfileData } from './../models/userAndProfileData.model';
import { StorageItems } from './../constants/storage-items.constants';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userCollection: string = 'Users';
  profilesCollection: string = 'Profiles';

  constructor(
    private afs: AngularFirestore,
    private service: FBaseService,
    private afFun: AngularFireFunctions
  ) {}

  getUserAndProfiles(userId: string): Promise<[User, Profile[]]> {
    return Promise.all([
      firstValueFrom(this.service.getById(this.userCollection, userId)) as Promise<User>,
      firstValueFrom(
        this.service.getByMultiField(
          this.profilesCollection,
          undefined,
          [{ field: 'userId', opStr: '==', value: userId }],
          [
            {
              active: 'primaryProfile',
              direction: 'desc',
            },
            { active: 'created', direction: 'asc' },
          ]
        )
      ) as Promise<Profile[]>,
    ]);
  }

  getUserByEmail(collectionName: string, email: string) {
    return this.afs
      .collection(collectionName, ref => {
        let query: CollectionReference | Query = ref;
        if (email) {
          query = query.where('email', '==', email);
        }
        return query;
      })
      .valueChanges();
  }

  async add(userData: any, password: string): Promise<any> {
    return await firstValueFrom(
      this.afFun.httpsCallable('userFunctions')({
        action: 'CREATE',
        userData,
        password,
      })
    );
  }

  async update(userData: any): Promise<any> {
    return await firstValueFrom(
      this.afFun.httpsCallable('userFunctions')({
        action: 'UPDATE',
        userData,
      })
    );
  }

  async delete(userId: string): Promise<any> {
    return await firstValueFrom(
      this.afFun.httpsCallable('userFunctions')({
        action: 'DELETE',
        userId,
      })
    );
  }

  async realDelete(userId: string): Promise<any> {
    return await firstValueFrom(
      this.afFun.httpsCallable('userFunctions')({
        action: 'REALDELETE',
        userId,
      })
    );
  }

  async deleteProfile(profileId: string): Promise<any> {
    return await firstValueFrom(
      this.afFun.httpsCallable('userFunctions')({
        action: 'DELETE_PROFILE',
        profileId,
      })
    );
  }

  async sendEmailVerification(userId: string): Promise<any> {
    return await firstValueFrom(
      this.afFun.httpsCallable('userFunctions')({
        action: 'SEND_EMAIL_VERIFICATION',
        userId: userId,
      })
    );
  }

  async confirmEmailVerification(token: string): Promise<any> {
    return await firstValueFrom(
      this.afFun.httpsCallable('userFunctions')({
        action: 'CONFIRM_EMAIL_VERIFICATION',
        token: token,
      })
    );
  }

  async sendEmailForgotPassword(email: string): Promise<any> {
    return await firstValueFrom(
      this.afFun.httpsCallable('userFunctions')({
        action: 'SEND_EMAIL_FORGOT_PASSWORD',
        email,
      })
    );
  }

  async validateChangePassword(token: string | null): Promise<any> {
    return await firstValueFrom(
      this.afFun.httpsCallable('userFunctions')({
        action: 'VALIDATE_CHANGE_PASSWORD',
        token,
      })
    );
  }

  async changePassword(
    token: string | null,
    newPass: string,
    publicRSAKey = environment.publicRSAKey
  ): Promise<any> {
    return await firstValueFrom(
      this.afFun.httpsCallable('userFunctions')({
        action: 'CHANGE_PASSWORD',
        token,
        newPass,
        publicRSAKey,
      })
    );
  }

  getUserAndProfileData(
    user?: boolean,
    profile?: boolean,
    profiles?: boolean,
    selectedProfileIndex?: boolean
  ): userAndProfileData {
    let u, p, ps, spi;
    let returnAll: boolean;
    if (
      user === undefined &&
      profile === undefined &&
      profiles === undefined &&
      selectedProfileIndex === undefined
    ) {
      returnAll = true;
    } else {
      returnAll = false;
    }
    if (user || returnAll) {
      u = JSON.parse(localStorage.getItem(StorageItems.USER)!);
    }

    if (profiles || returnAll) {
      ps = JSON.parse(localStorage.getItem(StorageItems.PROFILES)!);
    }

    if (selectedProfileIndex || returnAll) {
      spi = JSON.parse(localStorage.getItem(StorageItems.SELECTED_INDEX)!);
    }

    if (profile || returnAll) {
      p = JSON.parse(localStorage.getItem(StorageItems.PROFILE)!);
      if (!p) {
        if (ps) {
          p = ps[spi];
        }
      }
    }
    return { user: u, profile: p, profiles: ps, selectedProfileIndex: spi };
  }

  async setUserAndProfileData(
    user?: User | null,
    profiles?: Profile[] | null,
    selectedIndex?: any
  ): Promise<any> {
    if (user) {
      localStorage.setItem(StorageItems.USER, JSON.stringify(user));
    }
    if (profiles) {
      localStorage.setItem(StorageItems.PROFILES, JSON.stringify(profiles));
      let index =
        selectedIndex && selectedIndex !== -1
          ? selectedIndex
          : profiles?.findIndex(item => item.primaryProfile);
      localStorage.setItem(StorageItems.SELECTED_INDEX, index?.toString()!);
      let profile = profiles[index] || null;
      localStorage.setItem(StorageItems.PROFILE, JSON.stringify(profile));
    }
  }
}
