import { Injectable } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { Messaging, getToken, onMessage, isSupported } from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';
import { firstValueFrom, Observable } from 'rxjs';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/FCM';
import { LoggerService } from './logger.service';
import { User } from './../models/user.model';
import { UserService } from './user.service';
import { StorageService } from './storage.service';
import { TranslatePipe } from './../../shared/translate/pipes/translate.pipe';

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  supportedBrowser = true;
  alreadyInitialized = false;
  user: User;
  notificationSub: any;

  constructor(
    private platform: Platform,
    private afs: AngularFirestore,
    private userService: UserService,
    private logger: LoggerService,
    private storage: StorageService,
    private messaging: Messaging,
    private alertController: AlertController,
    private translate: TranslatePipe
  ) {
    this.detectBrowser();
  }

  async detectBrowser() {
    const ua = navigator.userAgent || navigator.vendor;
    // FACE
    if (ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1) {
      this.supportedBrowser = false;
    }
    // IE11
    if (ua.indexOf('Trident/7.0') > -1) {
      this.supportedBrowser = false;
    }
    //  OPERA MINI
    if (ua.indexOf('Opera Mini') > -1) {
      this.supportedBrowser = false;
    }
    if (!this.supportedBrowser) {
      await this.logger.addLogItem('notifications_not_supported', { ua: ua });
    }
  }

  initFirebase(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      if (this.alreadyInitialized) {
        await this.logger.addLogItem('Already initialized.');
        reject(false);
        setTimeout(() => {
          window.location.reload();
        }, 200);
        return;
      }
      if (!this.supportedBrowser) {
        await this.logger.addLogItem('Browser not supported.');
        reject(false);
        return;
      }
      const supported = await isSupported();
      if (!supported) {
        await this.logger.addLogItem('Firebase.messaging not supported.');
        reject(false);
        return;
      }
      try {
        const serviceWorkerRegistration = await navigator.serviceWorker.register(
          'firebase-messaging-sw.js',
          { type: 'module' }
        );
        await navigator.serviceWorker.ready;
        console.log(serviceWorkerRegistration);
        const options = {
          vapidKey: environment.firebase.vapidKey,
          serviceWorkerRegistration: serviceWorkerRegistration,
        };
        const token = await getToken(this.messaging, options);
        resolve(token);
        return;
      } catch (error) {
        reject(error);
        return;
      }
    });
  }

  async initNotifications() {
    try {
      const token = await this.initFirebase();
      await this.saveFCMtoken(token);
      onMessage(this.messaging, payload => {
        console.log('INCOMING NOTIFICATION');
        console.log(payload.notification);
        const title = payload.notification?.title || '';
        const body = payload.notification?.body || '';
        this.logger.showToast(title + ' · ' + body, 'dark', 5000, 'top');
      });
      this.alreadyInitialized = true;
    } catch (error) {
      console.error(error);
      this.logger.handleError(error);
    }
  }

  async checkNotifications() {
    const { user } = await this.userService.getUserAndProfileData();
    if (user) {
      this.user = user;
      if (user?.notifications?.push) {
        this.platform.ready().then(async () => {
          if (!this.platform.is('cordova')) {
            // WEB
            if (Notification && this.supportedBrowser) {
              await this.logger.addLogItem('browser_notification_permission', {
                permission: Notification.permission,
              });
              console.log(Notification.permission);
              if (Notification.permission === 'default') {
                this.askForPermission();
              } else if (Notification.permission === 'granted') {
                this.initNotifications();
              } else if (Notification.permission === 'denied') {
                this.clearNotifications();
              }
            }
          } else {
            const hasPermission = await FCM.requestPushPermission();
            // console.log({ hasPermission });
            if (hasPermission) {
              if (this.notificationSub) {
                this.notificationSub.unsubscribe();
              }
              const initialPushPayload: any = await FCM.getInitialPushPayload();
              if (initialPushPayload) {
                // console.log(JSON.stringify(initialPushPayload));
              }
              if (this.platform.is('android')) {
                const default_notification_channel: any = {
                  id: 'default_notification_channel',
                  name: 'Push notification',
                  description: 'General notifications',
                  importance: 'default',
                  visibility: 'public',
                  lights: true,
                  vibration: true,
                };
                await FCM.createNotificationChannel(default_notification_channel);
              }
              this.notificationSub = FCM.onNotification().subscribe((data: any) => {
                // console.log('FCM NOTIFICATION:', JSON.stringify(data));
                if (data.wasTapped) {
                  // console.log('Received in background');
                } else {
                  this.logger.showToast(data.body, 'primary', 2000, 'top');
                  // console.log('Received in foreground');
                }
              });
              FCM.onTokenRefresh().subscribe(async fcm_token => {
                await this.saveFCMtoken(fcm_token);
              });
              const fcm_token = await FCM.getToken();
              // console.log({ fcm_token });
              await this.saveFCMtoken(fcm_token);
            } else {
              // console.log('NO_PERMISSION');
            }
          }
        });
      } else {
        this.clearNotifications();
      }
    }
  }

  async askForPermission() {
    const alert = await this.alertController.create({
      header: this.translate.transform('PUSH_PERMISSION_HEADER'),
      message: this.translate.transform('PUSH_PERMISSION_MESSAGE'),
      backdropDismiss: false,
      buttons: [
        {
          text: 'Nem engedélyezem',
          role: 'cancel',
          handler: async () => {
            await this.logger.addLogItem('notifications_denied_by_user', {});
            this.clearNotifications();
          },
        },
        {
          text: 'Engedélyezem',
          handler: async () => {
            await this.logger.addLogItem('notifications_allowed_by_user', {});
            Notification.requestPermission().then(
              (notificationPermissions: NotificationPermission) => {
                this.logger.addLogItem('browser_notification_permission', {
                  permission: notificationPermissions,
                });
                if (notificationPermissions === 'granted') {
                  this.initNotifications();
                }
                if (notificationPermissions === 'denied') {
                  this.clearNotifications();
                }
              }
            );
          },
        },
      ],
    });
    await alert.present();
  }

  async saveFCMtoken(token: string) {
    try {
      const tokens: string[] = [];
      const newToken = {
        id: null,
        created: null,
        lastUpdated: null,
        email: this.user.email,
        tokens: tokens,
      };
      const existingToken =
        (await firstValueFrom(this.getFCMtokenByEmail(this.user.email)))[0] || null;
      if (existingToken) {
        newToken.id = existingToken.id || null;
        newToken.tokens = existingToken.tokens || [];
        newToken.created = existingToken.created || null;
      }
      if (!newToken.tokens.includes(token)) {
        newToken.tokens.push(token);
      }
      await this.storage.set('fcm_token', token);
      await this.saveOrUpdateFCMToken(newToken);
      // console.log('FCM TOKEN SAVED');
    } catch (error) {
      this.logger.handleError(error, true);
    }
  }

  async deleteFCMtoken(token: string) {
    try {
      const existingToken =
        (await firstValueFrom(this.getFCMtokenByEmail(this.user.email)))[0] || null;
      if (existingToken) {
        existingToken.tokens = existingToken.tokens.filter((t: string) => t !== token);
        await this.saveOrUpdateFCMToken(existingToken);
      }
      await this.storage.set('fcm_token', null);
    } catch (error) {
      this.logger.handleError(error, true);
    }
  }

  async clearNotifications() {
    // const existingToken =
    //   (await firstValueFrom(this.firebaseService.getFCMtokenByEmail(this.user.email)))[0] || null;
    // if (existingToken) {
    //   this.firebaseService.delete('FCM_tokens', existingToken.id);
    // }
    // if (!this.platform.is('cordova')) {
    //   await deleteToken(this.messaging);
    // }
    // this.user.notifications.push = false;
    // await this.firebaseService.update('Users', this.user.id, this.user);
    // this.alreadyInitialized = false;
  }

  saveOrUpdateFCMToken(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (data) {
        if (!data.id) {
          data.id = this.afs.createId();
        }
        if (!data.created) {
          data.created = new Date().toISOString();
        }
        data.lastUpdated = new Date().toISOString();
        this.afs
          .collection('FCM_tokens')
          .doc(data.id)
          .set(data)
          .then(() => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  }

  getFCMtokenByEmail(email: string): Observable<any[]> {
    return this.afs
      .collection('FCM_tokens', ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('email', '==', email);
        return query;
      })
      .valueChanges() as Observable<any[]>;
  }
}
